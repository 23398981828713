<template>
<div>
     <LiefengContent>
    <template v-slot:title>服务对象管理</template>
    <template v-slot:toolsbarRight >
        <!-- <Input
        :maxlength="20"
        enter-button
        placeholder="姓名"
        style="margin-right:10px;width: 150px"
      /> -->
      <Input
        v-model="search.address"
        :maxlength="20"
        enter-button
        width="100px"
        placeholder="请输入查询地址"
        style="margin-right:10px;width:150px"
        v-if="changeIndex == 0"
      />
      <Button type="primary" icon="ios-search" @click="searchBtn" v-if="changeIndex == 0">查询</Button>
      <Button type="success" icon="ios-refresh" style="margin-left: 10px;" v-if="changeIndex == 0" @click="reset">重置</Button>
      <Button type="primary" @click="addDistrubutionModal">添加服务对象</Button>
    </template>

    <!--身体部分-->
     <template v-slot:contentArea>
             <div class="container">
                 <div class="container-btn">
                     <div :class="changeIndex == index ?'item item-active':'item'" v-for="(item,index) in menuList" :key="index" @click="changeMenu(index)">{{item.name}}</div>
                 </div>
                 <LiefengTable
                    :talbeColumns="talbeColumns"
                    :tableData="tableData"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[50, 200, 500, 1000]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                ></LiefengTable>
             </div>
        </template>
  </LiefengContent>
   <!--服务对象分配-->
    <LiefengModal
        :value="distribution.status"
        @input="distributionStatusFn"
        title="服务对象分配"
        :fullscreen="true"
        class="distribution-div"
      >
        <template v-slot:contentarea>
            <Distribution></Distribution>
        </template>
        <template v-slot:toolsbar>
          <Button style="margin: 0 5px 0 13px" type="warning" @click="distribution.tip = true; distributionStatusFn(false);">取消</Button>
          <Button style="margin: 0 5px 0 13px" type="primary" @click="saveDistribute">保存</Button>
        </template>
    </LiefengModal>
    <!--新增服务对象-->
    <LiefengModal
        :value="addDistribution.status"
        @input="addDistributionStatusFn"
        :title="isEdit?'修改服务对象':'新增服务对象'"
        :fullscreen="true"
        class="distribution-div"
      >
        <template v-slot:contentarea>
           <AddDistribution v-if="isShow" :memberFromScope="memberFromScope" :isEdit="isEdit" :formData="formData" :cityOper="cityOper"></AddDistribution>
        </template>
        <template v-slot:toolsbar>
          <Button style="margin: 0 5px 0 13px" type="warning" @click="addDistribution.tip = true; addDistributionStatusFn(false);">取消</Button>
          <Button style="margin: 0 5px 0 13px" type="primary" @click="saveAddDistribute">保存</Button>
        </template>
    </LiefengModal>
    <!--新增服务对象-->
    <LiefengModal
        :value="distribution.status"
        @input="distributionStatusFn"
        title="服务对象分配"
        :fullscreen="true"
        class="distribution-div"
      >
        <template v-slot:contentarea>
            <Distribution ></Distribution>
        </template>
        <template v-slot:toolsbar>
          <Button style="margin: 0 5px 0 13px" type="warning" @click="distribution.tip = true; distributionStatusFn(false);">取消</Button>
          <Button style="margin: 0 5px 0 13px" type="primary" @click="saveDistribute">保存</Button>
        </template>
    </LiefengModal>
    <!--查看对象详情-->
    <LiefengModal
        :value="checkDistribution.status"
        @input="checkDistributionStatusFn"
        title="服务对象详情"
        :fullscreen="true"
        class="distribution-detail"
      >
        <template v-slot:contentarea>
           <checkDetail :detailMessage='detailMessage'></checkDetail>
        </template>
        <template v-slot:toolsbar>
          <!-- <Button style="margin: 0 5px 0 13px" type="warning" @click="checkDistribution.tip = true; checkDistributionStatusFn(false);">取消</Button> -->
          <Button style="margin: 0 5px 0 13px" type="primary" @click="saveCheckDistribute">确定</Button>
        </template>
    </LiefengModal>
   
  
     
</div>
 
</template>

<script>
  //@route("/jujiamanagement")
  import LiefengContent from "@/components/LiefengContent";
  import LiefengTable from "@/components/LiefengTable";
  import LiefengModal from "@/components/LiefengModal";
  import AddDistribution from './children/AddDistribution'
  import Distribution from './children/distribution'
  import checkDetail from './children/checkDetail'
  export default {
    //import引入的组件需要注入到对象中才能使用
    components: {LiefengContent,LiefengModal,LiefengTable,AddDistribution,Distribution,checkDetail},
    data() {
      //这里存放数据
      return {
          tableData:[],
        //   服务对象分配弹窗
          distribution:{
              status:false,
              tip:false
          },
        //   新增服务对象
        addDistribution:{
            status:false,
            tip:false
        },
        // 查看对象详情
        checkDistribution:{
            status:false,
            tip:false
        },
        // 选择服务人员
        choosePeople:{
            status:false,
            tip:false
        },
        //设备实施授权
        implementation:{
            status:false,
            tip:false,
            time:3
        },
        page:1,
        pageSize:20,
        total:0,
        curPage:0,
        loading:false,
        menuList:[
              {name:'正常',value:0},
              {name:'异常',value:1},
        ],
        changeIndex:0,
        search:{
          address:''
        },
        talbeColumns:[
           {
                title: "用户名称",
                key: "userName",
                minWidth: 200,
                align: "center"
            },
            {
              title:'用户头像',
              align:'center',
              width: 80,
              render:(h,params)=>{
                return h('div',[
                    h('img',{
                      props:{

                      },
                      style:{
                        width:'30px',
                        height:'30px',
                        borderRadius:'50%',
                        textAlign:'center',
                        display:params.row.avatarUrl?'block' : 'none'
                      },
                      attrs:{
                        src:params.row.avatarUrl,
                      }
                    },

                    )
                ])
              }
            },
             {
              title: '性别',
              minWidth: 100,
              align: "center",
              render:(h,params) =>{
                return h ('div',{
                },params.row.sex == 0 ?'未知':params.row.sex == 1 ?'男':params.row.sex == 2 ?'女':'')
              }
            },
            {
              title: '地址',
              minWidth: 100,
              align: "center",
              key:'addr'
            },
            {
              title: '详细地址',
              minWidth: 100,
              align: "center",
              key:'addrComplete'
            },
            {
                title:'操作',
                align:'center',
                minWidth:100,
                render:(h,params) =>{
                     return h(
                        "div",
                        {
                            style: {
                            textAlign: "left",
                            display:'flex',
                            justifyContent:'center'
                            },
                        },
                        [
                            h(
                            "Button",
                            {
                                attrs: {
                                type: "info",
                                size: "small",
                                },
                                on: {
                                    click: () => {
                                      this.memberFromScope = ''
                                      this.getCheck(params.row.userId)
                                    },
                                },
                                style: {
                                    marginRight: "10px",
                                    textAlign: "center",
                                },
                            },
                            "修改" //1001
                            ),       
                            h(
                            "Button",
                            {
                                attrs: {
                                type: "info",
                                size: "small",
                                },
                                on: {
                                    click: () => {
                                       this.getDetail(params.row.userId)
                                    },
                                },
                                style: {
                                    marginRight: "10px",
                                    textAlign: "center",
                                },
                            },
                            "查看" //1001
                            ),       
                            h(
                            "Button",
                            {
                                attrs: {
                                type: "info",
                                size: "small",
                                },
                                on: {
                                    click: () => {
                                      this.$Modal.confirm({
                                            title:'温馨提示',
                                            content:'<p>确认要删除该数据吗？</p>',
                                            onOk: () => {
                                              this.deleteRow(params.row.userId)
                                          }
                                      })
                                    },
                                },
                                style: {
                                    marginRight: "10px",
                                    textAlign: "center",
                                },
                            },
                            "删除" //1001
                            ),       
                         ]
                     );
                }
            }
        ],
        // 表单内容
        formData:{
          address:'',
          avatarUrl:'',
          idNum:'',
          serviceGrade:'',
          serviceType:'',
          sex:'',
          testifyUrl:'',
          userName:'',
          longitude:'',
          latitude:''
        },
        // 选择的城市内容
        cityOper:{
          city:'',
          region:'',
          stree:'',
          project:''
        },
        isEdit:false,
        detailMessage:{},
        isShow:true,
        checkMessage:{},
        // 存放修改的时候带的地址
        memberFromScope:'',
        isToken:''
      }
    },
    //方法集合
    methods: {
        // 方法部分
        // 重置按钮
        reset(){
          this.page =1,
          this.pageSize = 20,
          this.search.address = ''
          this.getPeopleList()
        },
        // 点击查询按钮
        searchBtn(){
          this.page = 1
          this.pageSize = 20
          if(this.changeIndex == 0){
            // 点击查询全部
            this.getPeopleList()
          }else{
            // 点击查询异常的
            this.getAlarmList()
          }
        },
          // 改变菜单选项
          changeMenu(index){
            this.changeIndex = index
            this.page = 1
            this.pageSize = 20
            if(this.changeIndex == 0){
              // 点击查询全部
              this.getPeopleList()
            }else{
              // 点击查询异常的
              this.getAlarmList()
            }
        },
        // 改变val
         hadlePageSize(val) {//改变每页条数
         this.page = val.page
         this.pageSize = val.pageSize
         if(this.changeIndex == 0){
            // 点击查询全部
            this.getPeopleList()
          }else{
            // 点击查询异常的
            this.getAlarmList()
          }
        },
        //   点击删除服务按钮
          deleteItem(){
              this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>确认要删除该数据吗？</p>",
                onOk: () => {
                    
                    }
                });
          },
        // 设备实施保存按钮
        saveImplement(){
            this.implementation.status = false;
        },  
        //点击打开实施授权按钮   
        openImplement(){
            this.implementation.status = true;
        },
        //   关闭选择实施的事件
        implementationStatusFn(status) {
            if (!status && this.implementation.tip) {
                this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
                onOk: () => {
                    this.implementation.status = status;
                }
                });
            } else {
                this.implementation.status = status;
            }
        },                                 
         // 关闭服务对象模态框事件
        distributionStatusFn(status) {
            if (!status && this.distribution.tip) {
                this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
                onOk: () => {
                    this.distribution.status = status;
                }
                });
            } else {
                this.distribution.status = status;
            }
        },
        // 服务对象保存按钮
        saveDistribute(){
            this.distribution.status = false  
            this.distribution.tip = false 
        },
        // 点击服务对象按钮
        openDistrubutionModal(){
            this.distribution.status = true
        },
        // 点击新增服务对象按钮
        addDistrubutionModal(){
            this.addDistribution.status = true
            this.isEdit = false
            this.isShow = false;
            this.$nextTick(() => {
                this.isShow = true;
            });
        },
        // 关闭新增服务对象模态框事件
        addDistributionStatusFn(status) {
            if (!status && this.addDistribution.tip) {
                this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
                onOk: () => {
                    this.addDistribution.status = status;
                    this.addDistribution.tip = false
                    this.cityOper = {
                      city:'',
                      region:'',
                      stree:'',
                      project:''
                    }
                    this.formData = {
                        address:'',
                        avatarUrl:'',
                        idNum:'',
                        serviceGrade:'',
                        serviceType:'',
                        sex:'',
                        testifyUrl:'',
                        userName:'',
                        longitude:'',
                        latitude:''
                    }
                    this.isShow = false;
                    this.$nextTick(() => {
                        this.isShow = true;
                    });
                }
                });
            } else {
                this.addDistribution.status = status;
            }
        },
        // 点击新增服务对象保存按钮
        saveAddDistribute(){
          if(this.formData.userName == ''){
            this.$Message.warning({
              content:'姓名不能为空',
              background:true
            })
            return
          }
          if(this.idCard(this.formData.idNum) != ''){
            this.$Message.warning({
              content:this.idCard(this.formData.idNum),
              background:true
            })
            return
          }
          if(this.cityOper.city =='' || this.cityOper.region == '' || this.cityOper.stree =='' || this.cityOper.project ==''){
            this.$Message.warning({
              content:'家庭地址不能为空且需要选择到街道',
              background:true
            })
            return
          }
          if(this.formData.address == ''){
            this.$Message.warning({
              content:'详细地址不能为空',
              background:true
            })
            return
          }
          if(!this.isEdit){
            // 新增跑这里
            let data = {
                address: this.formData.address,
                avatarUrl: this.formData.avatarUrl,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                familyId: "",
                idNum: this.formData.idNum,
                orgId:parent.vue.loginInfo.userinfo.orgUserVOS[0].orgId,
                serviceGrade: this.formData.serviceGrade,
                serviceType: this.formData.serviceType,
                sex: this.formData.sex,
                testifyUrl: this.formData.testifyUrl,
                userId: "",
                userName: this.formData.userName
            }
            this.updateAdd(data)
          }else{
            // 修改跑这里
            let data = {
                address: this.formData.address,
                avatarUrl: this.formData.avatarUrl,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                familyId: this.checkMessage.familyId,
                idNum: this.formData.idNum,
                orgId:parent.vue.loginInfo.userinfo.orgUserVOS[0].orgId,
                serviceGrade: this.formData.serviceGrade,
                serviceType: this.formData.serviceType,
                sex: this.formData.sex,
                testifyUrl: this.formData.testifyUrl,
                userId: this.checkMessage.userId,
                userName: this.formData.userName
            }
            this.updateAdd(data)
          }
            
        },
         exportModalFn(status) {
              this.excelStatus = status;
        },
        // 
        checkDistributionStatusFn(status){
             if (!status && this.checkDistribution.tip) {
                this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
                onOk: () => {
                    this.checkDistribution.status = status;
                }
                });
            } else {
                this.checkDistribution.status = status;
            }
        },
        // 点击查看对象详情按钮
        openCheckDatail(){
              this.checkDistribution.status = true;
        },
        // 查看对象确认按钮
        saveCheckDistribute(){
            this.checkDistribution.status = false;
            this.checkDistribution.tip = false
        },
        // 接口部分
        // 获取服务对象接口
        getPeopleList(){
          this.loading = true
          this.$get('/gateway/api/homecare/pc/ServicePeople/selectServicePeoplePage',{
            page:this.page,
            pageSize:this.pageSize,
            orgId:parent.vue.loginInfo.userinfo.orgUserVOS[0].orgId,
            address:this.search.address
          },{
                Authorization:this.isToken
            }).then(res=>{
            if(res.code == 200){
              this.loading = false
              this.tableData = res.dataList,
              this.total = res.maxCount
              this.curPage = res.currentPage
            }else{
                this.loading = false
                this.$Message.error({
                     content:res.msg,
                     background:true
                 })
                 return
            }
          })
        },
        // 异常服务对象接口
        getAlarmList(){
          this.loading = true
          this.$get('/gateway/api/homecare/pc/ServicePeople/AlarmServicePeopleList',{
            page:this.page,
            pageSize:this.pageSize,
            orgId:parent.vue.loginInfo.userinfo.orgUserVOS[0].orgId,
          },{
                Authorization:this.isToken
            }).then(res=>{
            if(res.code == 200){
              this.loading = false
              this.tableData = res.dataList
              this.total = res.maxCount
              this.curPage = res.currentPage
              
            }else{
              this.loading = false
              this.$Message.error({
                     content:res.desc,
                     background:true
                 })
              return
              
            }
          })
        },
        // 添加和修改服务对象接口
        updateAdd(data){
          this.$post('/gateway/api/homecare/pc/ServicePeople/modifyOrAddServicePeople',{
            ...data
          },{"Content-Type": "application/json", Authorization:this.isToken}).then(res=>{
            if(res.code == 200){
              this.$Message.success({
                content:'操作成功',
                background:true
              })
              this.addDistribution.status =false
              this.addDistribution.tip = false
              this.page = this.curPage
              this.getPeopleList()
            }else{
               this.$Message.error({
                content:res.desc,
                background:true
              })
            }
          })
        },
        // 删除接口
        deleteRow(data){
          this.$post('/gateway/api/homecare/pc/ServicePeople/deleteServicePeople',{
            orgId:parent.vue.loginInfo.userinfo.orgUserVOS[0].orgId,
            userId:data
          },{ Authorization:this.isToken}).then(res=>{
            if(res.code == 200){
              this.$Message.success({
                content:'删除成功',
                background:true
              })
              this.page = this.curPage
              if(this.changeIndex == 0){
                this.getPeopleList()
              }else{
                this.getAlarmList()
              }      
              return
            }else{
              this.$Message.error({
                content:res.desc,
                background:true
              })
              return
            }
          })
        },
        // 查看的接口
        getDetail(data){
          this.$Message.destroy();
          this.$Message.loading({
              content: "正在加载信息，请稍等...",
              duration: 0,
              background:true
          });
          this.$get('/gateway/api/homecare/pc/ServicePeople/selectServiceByUserId',{
            orgId:parent.vue.loginInfo.userinfo.orgUserVOS[0].orgId,
            userId:data
          },{ Authorization:this.isToken}).then(res=>{
            if(res.code == 200){
              this.$Message.destroy();
              this.checkDistribution.status = true
              this.detailMessage = res.data
            }else{
              this.$Message.destroy();
              this.$Message.error({
                content:res.desc,
                background:true
              })
              return
            }
          })
        },
        // 查看的接口
        getCheck(data){
          this.$Message.destroy();
          this.$Message.loading({
              content: "正在加载信息，请稍等...",
              duration: 0,
              background:true
          });
          this.$get('/gateway/api/homecare/pc/ServicePeople/selectServiceByUserId',{
            orgId:parent.vue.loginInfo.userinfo.orgUserVOS[0].orgId,
            userId:data
          },{ Authorization:this.isToken}).then(res=>{
            if(res.code == 200){
              this.$Message.destroy();
              this.isEdit = true
              this.addDistribution.status = true
              this.checkMessage = res.data
              this.formData ={
                   address:this.checkMessage.addrComplete,
                   avatarUrl:this.checkMessage.avatarUrl,
                   idNum:this.checkMessage.idNum,
                   serviceGrade:this.checkMessage.serviceGrade,
                   serviceType:this.checkMessage.serviceType,
                   sex:this.checkMessage.sex,
                   testifyUrl:this.checkMessage.testifyUrl,
                   userName:this.checkMessage.userName,
                   longitude:this.checkMessage.longitude,
                   latitude:this.checkMessage.latitude
              }
              this.cityOper = {
                city : this.checkMessage.cityCode,
                region:this.checkMessage.countyCode,
                stree:this.checkMessage.streetCode,
                communityCode:this.checkMessage.communityCode
              }
              this.memberFromScope = this.checkMessage.provinceCode+'-'+this.checkMessage.cityCode+'-'+this.checkMessage.countyCode+'-'+this.checkMessage.streetCode+'-'+this.checkMessage.communityCode
              this.memberFromScope = this.memberFromScope.toString()
            }else{
              this.$Message.destroy();
              this.$Message.error({
                content:res.desc,
                background:true
              })
              return
            }
          })
        },
        // 公共方法
        idCard(identityId){
          var patrn = /(^\d{15}$)|(^\d{17}(\d|X|x)$)/;//长度或格式校验
          //地区校验
          var aCity = {
            11: "北京",
            12: "天津",
            13: "河北",
            14: "山西",
            15: "内蒙古",
            21: "辽宁",
            22: "吉林",
            23: "黑龙江",
            31: "上海",
            32: "江苏",
            33: "浙江",
            34: "安徽",
            35: "福建",
            36: "江西",
            37: "山东",
            41: "河南",
            42: "湖北",
            43: "湖南",
            44: "广东",
            45: "广西",
            46: "海南",
            50: "重庆",
            51: "四川",
            52: "贵州",
            53: "云南",
            54: "西藏",
            61: "陕西",
            62: "甘肃",
            63: "青海",
            64: "宁夏",
            65: "新疆",
            71: "台湾",
            81: "香港",
            82: "澳门",
            91: "国外"
          };
          // 出生日期验证
          var sBirthday = (
            identityId.substr(6, 4) +
            "-" +
            Number(identityId.substr(10, 2)) +
            "-" +
            Number(identityId.substr(12, 2))
          ).replace(/-/g, "/"),
            d = new Date(sBirthday)
          // 身份证号码校验 最后4位  包括最后一位的数字/字母X
          var sum = 0,
            weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2],
            codes = "10X98765432";
          for (var i = 0; i < identityId.length - 1; i++) {
            sum += identityId[i] * weights[i];
          }
          var last = codes[sum % 11]; //计算出来的最后一位身份证号码

          var errorMsg = '';
          if (identityId === '') {
            errorMsg = "身份证号不能为空"
          } else if (!patrn.exec(identityId)) {
            errorMsg = "你输入的身份证长度或格式错误"
          } else if (!aCity[parseInt(identityId.substr(0, 2))]) {
            errorMsg = "你的身份证地区非法"
          } else if (sBirthday != d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate()) { errorMsg = "身份证上的出生日期非法" } else if (identityId[identityId.length - 1] != last) {
            errorMsg = "你输入的身份证号非法"
          }
          return errorMsg;
        }
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
      this.isToken = 'bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiIxNzMyODY4MDAyNCIsInNjb3BlIjpbImFsbCJdLCJleHAiOjE2MjQ0NTI2NDQsImF1dGhvcml0aWVzIjpbIlJPTEVfVVNFUiJdLCJqdGkiOiJlMWFjZDRkZC0xYjFiLTQyMDMtYjhmNC02ODgwOGFiMjMyZTAiLCJjbGllbnRfaWQiOiJzdWlhbmp1IiwidG9rZW4iOiJzdWlhbmp1bG9naW5fdG9rZW5fNTRhMTg3OWI3MjA4NDg2NDlkNjZkOTI4MmY3ODdjNDQifQ.fHzGznTFAPJeOAbBba7RypGKCDFdLPg2mOZ-WvxJOgwzQzu_yguPnmRfAu6Z7Dh-8p8h7uMGEeqKtF4NKihg_KN1x86K8Lbe01ZYgwUAQe8RoYJcJ1Llr7CddRt17GP3U3GLqnUJG5wUTqZIAfHQyUYT4JALQyNBPt1HBcsEQt4'
      this.getPeopleList()
    },
  }
</script>
<style lang='less' scoped>
  //编写css
  .container{
      .container-btn{
          display: flex;
          justify-content: flex-start;
          margin-bottom: 20px;
          .item{
              width: 80px;
              height: 40px;
              line-height: 40px;
              text-align: center;
              border: 1px solid rgba(000, 000, 000, 0.1);
              margin-right: 10px;
              cursor: pointer;
              user-select: none;
              border-radius: 5px;
               &-active{
                    background: #2d8cf0;
                    color: #ffffff;
                }
        }
      }
     
  }
.distribution-detail{
  /deep/#modal_contentarea{
  overflow: hidden !important;
}
}

</style>